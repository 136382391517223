import { getPlatformServiceBaseUrl } from '@informa/deployment-urls'
import { authInitialState } from '../slices/auth.slice'
import { ConfigState, RootState, AuthState } from '../types'
import { UIConfig, getClientIdForELN } from '@scm/ui-core'

export type GenerateStatePayload = {
  featureToggles: any
  testAutomationFlags: any
  brand: any
  environment: any
  configState: ConfigState
  standaloneRegistrationPage?: boolean
}

export const getAuthData = (brand: string, env: string) => {
  if (typeof window !== 'undefined') {
    const rawData = window.localStorage.getItem(`${brand}-${env}-auth`)
    if (typeof rawData === 'string') {
      const data = JSON.parse(rawData) as AuthState['user']
      return data || null
    }
    return null
  }
  return null
}

export const storeAuthData = (brand: string, env: string, data: object) => {
  if (typeof window !== 'undefined') {
    const serializedData = JSON.stringify(data)
    window.localStorage.setItem(`${brand}-${env}-auth`, serializedData)
  }
}

export const generateState = ({
  featureToggles,
  testAutomationFlags,
  brand,
  environment,
  configState,
  standaloneRegistrationPage = false,
}: GenerateStatePayload): RootState => {
  const authData = getAuthData(brand, environment)
  return {
    auth: {
      ...authInitialState,
      brand,
      environment,
      platformServiceBaseUrl: getPlatformServiceBaseUrl(environment),
      clientIdForELN: getClientIdForELN(brand),
      standaloneRegistrationPage,
      ...authData,
    },
    flags: { featureToggles, testAutomation: testAutomationFlags },
    config: configState,
    layout: { spinnerLoader: false, gtagReady: false },
  }
}

type CreateConfigStatePayload = {
  ui: UIConfig
  unbounceURL?: string
  elnConfig: ConfigState['newsletterSignupPromo']['elnConfig']
  brightcoveAccountId: string
  image: ConfigState['image']
}

export const createConfigState = ({
  ui,
  image,
  elnConfig,
  unbounceURL,
  brightcoveAccountId,
}: CreateConfigStatePayload): ConfigState => {
  return {
    font: ui.font,
    theme: ui.theme,
    uiBrand: ui.brand,
    colors: ui.colors,
    isMarketsDivision: false,
    image,
    media: { brightcoveAccountId },
    newsletterSignupPromo: { unbounceURL, elnConfig },
    isOneTrustLoaded: false,
  }
}
