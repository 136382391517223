import { createSlice } from '@reduxjs/toolkit'
import { ConfigState } from '../types'
import { Brand, Theme, isMarketsDivision } from '@scm/ui-core'
export const configInitialState: ConfigState = {
  uiBrand: Brand.DEMO,
  isMarketsDivision: false,
  colors: {},
  font: { primary: { name: '', url: '' }, secondary: { name: '', url: '' } },
  theme: Theme.THEME_1,
  image: { brand: '', contributor: '' },
  newsletterSignupPromo: { elnConfig: undefined, unbounceURL: undefined },
  media: { brightcoveAccountId: '' },
  isOneTrustLoaded: false,
}
export const configSlice = createSlice({
  name: 'config',
  initialState: configInitialState,
  reducers: {
    init(state, { payload }) {
      Object.assign(state, payload)
      if (payload.brand) {
        state.uiBrand = payload.brand
      }
      state.isMarketsDivision = isMarketsDivision(state.uiBrand)
    },
    update(state, { payload }) {
      Object.assign(state, payload)
      state.isMarketsDivision = isMarketsDivision(state.uiBrand)
    },
    clear(state) {
      Object.assign(state, configInitialState)
    },
    oneTrustScriptLoaded(state) {
      state.isOneTrustLoaded = true
    },
  },
})
