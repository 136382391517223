import Cookies, { CookieAttributes } from 'js-cookie'

export const COOKIE_TOKEN = 'IRIS_TOKEN'

const COOKIE_DEFAULT_CONFIGS: CookieAttributes = {
  sameSite: 'Strict',
  secure: true,
  expires: 30,
}

export const removeAuthToken = () => {
  Cookies.remove(COOKIE_TOKEN, COOKIE_DEFAULT_CONFIGS)
}

export const setAuthToken = (token: string, overwrite = true) => {
  if (!overwrite && Cookies.get(COOKIE_TOKEN)) return undefined
  Cookies.set(COOKIE_TOKEN, token, COOKIE_DEFAULT_CONFIGS)
}

export const getCookieToken = (req?: { cookies: any | null }): string => {
  const token = req ? req?.cookies[COOKIE_TOKEN] : Cookies.get(COOKIE_TOKEN)
  return token || ''
}
