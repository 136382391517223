import { UnknownUserInfo } from '../types'

export const userEventKeys = [
  'agriculture_title',
  'audience_source',
  'business_type',
  'company',
  'company_domain',
  'company_id',
  'company_revenue',
  'company_size',
  'company_type',
  'country',
  'farming_acres',
  'id',
  'industry_level1',
  'industry_level2',
  'job_area',
  'job_function',
  'job_level',
  'job_title',
  'state',
  'state_name',
] as const

export const unknownUserInfo: UnknownUserInfo =
  userEventKeys.reduce<UnknownUserInfo>((acc, curr) => {
    acc[curr] = undefined
    return acc
  }, {} as UnknownUserInfo)
