import { authInitialState } from './slices/auth.slice'
import { flagsInitialState } from './slices/flags.slice'
import { layoutInitialState } from './slices/layout.slice'
import { configInitialState } from './slices/config.slice'

export const mockedStore = {
  auth: { ...authInitialState },
  flags: { ...flagsInitialState },
  config: { ...configInitialState },
  layout: { ...layoutInitialState },
}
