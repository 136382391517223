import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { AuthState } from '../types'
import { Brand, removeAuthToken, setAuthToken } from '@scm/ui-core'
import { getGatingWithExternalFormFromCookies } from '../utils/externalGating'

export const authInitialState: AuthState = {
  brand: Brand.DEMO,
  isClientLoaded: false,
  isIdentityScriptLoaded: false,
  isProfileMgrScriptLoaded: false,
  isPassportScriptLoaded: false,
  isLoaded: false,
  user: null,
  dataLayerUser: null,
  token: null,
  accessToken: null,
  authFlow: 'anonymous',
  sessionType: 'anonymous',
  authenticationConfig: null,
  baseUrl: '',
  clientIdForELN: '',
  environment: 'preview',
  platformServiceBaseUrl: '',
  withNewsletterPromo: false,
  authenticationPending: false,
  gatingWithExternalFormPassed: getGatingWithExternalFormFromCookies(),
  standaloneRegistrationPage: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    init(state, { payload }) {
      Object.assign(state, payload)
      if (payload.token) setAuthToken(payload.token, false)
    },
    updateUser(state, { payload }) {
      Object.assign(state, payload)
      state.sessionType = 'user'
      state.accessToken = payload.token ? `Bearer ${payload.token}` : ''
      if (payload.token) setAuthToken(payload.token)
    },
    clearUser(state) {
      state.user = null
      state.dataLayerUser = null
      state.token = null
      state.accessToken = null
      state.authFlow = 'anonymous'
      state.sessionType = 'anonymous'
      removeAuthToken()
    },
    patch(state, { payload }) {
      Object.assign(state, payload)
    },
    scriptLoaded(state, { payload }) {
      Object.assign(state, payload)
      if (state.isPassportScriptLoaded) {
        state.isLoaded = true
        state.isIdentityScriptLoaded = true
        state.isProfileMgrScriptLoaded = true
      } else {
        state.isLoaded =
          state.isIdentityScriptLoaded && state.isProfileMgrScriptLoaded
      }
    },
    clear(state) {
      Object.assign(state, authInitialState)
    },
    gatingWithExternalFormUpdated(
      state,
      { payload }: PayloadAction<Record<string, string>>,
    ) {
      state.gatingWithExternalFormPassed = payload
    },
  },
})

export const { gatingWithExternalFormUpdated } = authSlice.actions
