import Cookies from 'js-cookie'

export const ANONYMOUS = 'anonymous'
const GATING_PASSED_FOR = 'gating-passed-for'

export const getGatedCookiesKey = (uid: string) => `${GATING_PASSED_FOR}-${uid}`

export const getGatingWithExternalFormFromCookies = () => {
  const allCookies = Cookies.get()

  if (!allCookies) {
    return {}
  }

  const gatingEntries = Object.entries(allCookies).filter(([key]) =>
    key.startsWith(GATING_PASSED_FOR),
  )

  return Object.fromEntries(gatingEntries)
}
