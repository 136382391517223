import { createSlice } from '@reduxjs/toolkit'
import { FlagsState } from '../types'
export const flagsInitialState: FlagsState = {
  featureToggles: {
    iirisAuthentication: false,
    elnNewsletter: false,
    elnWithoutNewsletter: false,
    subBrand: false,
    useSettingsRedirectAPIVersion1: false,
    iirisRecommendationDisabled: false,
    iasPetEnabled: false,
    bridgedMediaEnabled: false,
    ecommerceEnabled: false,
    weather3MonthForecastEnabled: false,
    iirisRecommendationVer: 'version3',
    legacySidebar: false,
    useMassRedirectsEngine: false,
  },
  testAutomation: {
    disableAds: false,
    disableIirisAuth: false,
    disableWelcomeAd: false,
    disableBigskyAd: false,
    disableIirisRecommend: false,
    disableDcts: false,
    disableWeatherWidget: false,
    disableCashGrainWidget: false,
    disableCommoditiesWidget: false,
    disableCaptcha: false,
    overrideBrandStyling: null,
    overrideThemeStyling: null,
  },
}
export const flagsSlice = createSlice({
  name: 'flags',
  initialState: flagsInitialState,
  reducers: {
    init(state, { payload }) {
      Object.assign(state, payload)
    },
    initFeatureToggles(state, { payload }) {
      state.featureToggles = { ...state.featureToggles, ...payload }
    },
    initTestAutomation(state, { payload }) {
      state.testAutomation = { ...state.testAutomation, ...payload }
    },
    update(state, { payload }) {
      Object.assign(state, payload)
    },
    clear(state) {
      Object.assign(state, flagsInitialState)
    },
  },
})
