/* eslint-disable no-console */
import { useLocation } from '@remix-run/react'
import { useCallback, useEffect, useRef } from 'react'
import { TopicSelector } from '@scm/ui-types'
import {
  CategoryTerm,
  EventType,
  IDataLayerEvent,
  ModuleLocation,
  ScmVersion,
} from './types'

import { DataLayerUser, selectors, unknownUserInfo } from '@scm/redux-store'
import isEqual from 'lodash/isEqual'

interface GtmProps {
  primary?: string | null
  secondary?: string | null
}

const Scripts = ({ primary, secondary }: GtmProps) => {
  const gtmIds = [primary, secondary].filter(id => id)
  const noscriptRef = useRef<HTMLScriptElement>(null)
  useEffect(() => {
    gtmIds.forEach(id => {
      const src = `https://www.googletagmanager.com/gtm.js?id=${id}`
      const element = document.querySelector(`script[src="${src}"]`)
      if (element) return null
      // if GTM script does no exists, append it to the head
      const script = document.createElement('script')
      script.async = true
      script.setAttribute('data-ot-ignore', '')
      script.src = src
      document.head.appendChild(script)
      // append the noscript iframe
      if (noscriptRef.current) {
        const iframe = document.createElement('iframe')
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`
        iframe.height = '0'
        iframe.width = '0'
        iframe.style.display = 'none'
        iframe.style.visibility = 'hidden'
        noscriptRef.current.appendChild(iframe)
      }
    })
    pushToDataLayer({
      'gtm.start': new Date().getTime(),
      event: EventType.GTM,
    })
  }, [primary, secondary])

  return <noscript ref={noscriptRef} />
}

export const pushToDataLayer = (
  payload: IDataLayerEvent,
  checkLast = false,
) => {
  if (typeof window !== 'undefined') {
    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = []
    } else if (checkLast) {
      for (let i = 0; i < window.dataLayer.length; i++) {
        const { event, data } = window.dataLayer[i] || {}

        if (payload.event === event && isEqual(data || {}, payload?.data || {}))
          return
      }
    }
    window.dataLayer.push(payload)
  }
}

const useSendEvent = () => useCallback(pushToDataLayer, [])

const resetDataLayer = () => {
  if (
    window.dataLayer !== undefined &&
    window.google_tag_manager !== undefined
  ) {
    const gtmContainerReg = /GTM-/i

    for (const gtmKey of Object.keys(window.google_tag_manager)) {
      if (
        gtmContainerReg.test(gtmKey) &&
        window.google_tag_manager[gtmKey].dataLayer &&
        window.google_tag_manager[gtmKey].dataLayer.reset
      ) {
        // eslint-disable-next-line no-console
        console.log(`Reset GTM-${gtmKey}`)
        window.google_tag_manager[gtmKey].dataLayer.reset()
      }
    }
  }
}

const useLoadEvent = (
  pageAttributes = undefined as IDataLayerEvent['page'],
  onPush?: () => void,
) => {
  const location = useLocation()
  const { dataLayerUser } = selectors.useAuth()
  const { isOneTrustLoaded } = selectors.useConfig()

  useEffect(() => {
    resetDataLayer()
    if (!pageAttributes) {
      pushOnLoadEvent(null, onPush)
      return
    }
    const payload = {
      page: {
        ...pageAttributes,
        attributes: {
          ...pageAttributes.attributes,
          scmVersion: ScmVersion.V2,
        },
      },
    }
    pushOnLoadEvent(payload, onPush)
  }, [location.key])

  useEffect(() => {
    if (isOneTrustLoaded) {
      pushOneTrustEvent()
      pushIirisEvent(dataLayerUser)
    }
  }, [location.key, isOneTrustLoaded])
}

const pushOnLoadEvent = (payload: any = {}, callback?: any) => {
  pushToDataLayer({
    event: EventType.PAGE_LOAD,
    ...payload,
  })
  callback?.()
}

const pushOneTrustEvent = () => {
  pushToDataLayer({
    event: EventType.PAGE_LOAD_ONE_TRUST_CONSENT,
  })
}

const pushIirisEvent = (userInfo: DataLayerUser | null) => {
  pushToDataLayer({
    event: EventType.PAGE_LOAD_IIRIS_CONSENT,
    user: userInfo || unknownUserInfo,
    _clear: true,
  })
}

export const useGtmClick = (
  title: string,
  moduleLocation: ModuleLocation,
  uid: string,
  type?: string,
) => {
  const url = getCurrentUrl()

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: uid,
      ...(type ? { type } : {}),
      referring_page: url.pathname,
      destination_page: prepareDestinationPath(href, url),
    }
    pushToDataLayer({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  return { trackGtmClick }
}

const getDestinationURL = (): string => {
  if (typeof window === 'undefined') return ''

  const currentLocation = window.location

  return currentLocation.origin + currentLocation.pathname
}

const getCategoryTerm = (
  categoryTerm: TopicSelector | undefined,
): CategoryTerm => {
  if (categoryTerm === undefined) {
    return {
      parent: '',
      child: '',
      grandchild: '',
    }
  }

  switch (categoryTerm.type) {
    case 'TopicTertiary': {
      return {
        parent: categoryTerm?.parentTopic?.parentTopic?.title ?? '',
        child: categoryTerm?.parentTopic?.title ?? '',
        grandchild: categoryTerm.title,
      }
    }

    case 'TopicSecondary': {
      return {
        parent: categoryTerm?.parentTopic?.title ?? '',
        child: categoryTerm.title,
        grandchild: '',
      }
    }

    default:
      return {
        parent: categoryTerm.title,
        child: '',
        grandchild: '',
      }
  }
}

export const getCurrentUrl = () => {
  if (typeof window === 'undefined') return null
  return new URL(window.location.href)
}

const preparePathname = (url: string) => url.split('?')[0]

export const prepareDestinationPath = (href: string, url: URL) =>
  href.startsWith('/')
    ? `${url.origin}${preparePathname(href)}`
    : preparePathname(href)

export const GTM = {
  Scripts,
  hooks: {
    useSendEvent,
    useLoadEvent,
    useGtmClick,
  },
  utils: {
    getDestinationURL,
    getCategoryTerm,
    getCurrentUrl,
    prepareDestinationPath,
    pushToDataLayer,
  },
}
