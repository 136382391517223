import { IIirisUser } from '@scm/ui-authentication'
import { DataLayerUser } from '@scm/redux-store'
import { IEcommerce } from './ecomTypes'
declare global {
  interface Window {
    dataLayer: Record<string, any>[]
    google_tag_manager: Record<string, any>
    location: Location
  }
}

export enum ScmVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum PrimaryGtmIds {
  BUSINESS_MARKETS = 'GTM-WH8BGZQ',
  BUSINESS_TECH = 'GTM-5523ZCM',
  BUSINESS_CONNECT = 'GTM-N49ZKQB',
}

export enum NewsletterSubscribeButtonLocation {
  HOME_PAGE_MODULE = 'home_page_module',
  MAIN_MENU = 'main_menu',
  FOOTER_MENU = 'footer_menu',
  MEGA_MENU = 'mega_menu',
  NEWSLETTER_MODULE = 'newsletter_module',
}

export enum RegistrationStatus {
  ATTEMPT = 'Submit Attempt',
  SUCCESS = 'Submit Success',
  FAIL = 'Submit Fail',
  REG_ADDITIONAL_INFO = 'Reg Additional Info - Completed',
}

export type SecondaryGtmId = string

export type GtmIds = {
  primary: PrimaryGtmIds | null
  secondary: SecondaryGtmId | null
}

export enum EventType {
  LOGIN = 'login',
  LOGOUT = 'logout',
  PAGE_LOAD = 'pageLoad',
  PAGE_LOAD_ONE_TRUST_CONSENT = 'pageLoad_consentData',
  PAGE_LOAD_IIRIS_CONSENT = 'pageLoad_userData',
  SLIDE_LOAD = 'slideLoad',
  SLIDESHOW = 'slideshow',
  ERROR_PAGE_LOAD = 'errorPage',
  NEWSLETTER = 'newsletter',
  ARTICLE = 'article',
  DOCUMENT = 'document',
  JOURNAL = 'journal',
  VIDEO = 'video',
  PUBLICATION_ISSUE = 'publication_issue',
  SOCIAL_SHARE = 'social_share',
  NAVIGATION = 'navigation',
  REGISTRATION = 'registration',
  DATA_CENTER_ADS_BLOCKED = 'DataCenterAdsBlocked',
  WEBINAR = 'webinar',
  MULTI_SECTION = 'multiSection',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  VIEW_ITEM_LIST = 'view_item_list',
  SELECT_ITEM = 'select_item',
  GTM = 'gtm.js',
  PODCAST = 'podcast',
  ENHANCED_LIST_LANDING_PAGE = 'enhanced_list_landing_page',
  ENHANCED_LIST_CHILD_PAGE = 'enhanced_list_child_page',
}

export type Contributor = {
  /**
   * From field in Content Stack
   */
  name: string

  /**
   * From field in Content Stack
   */
  jobTitle: string

  /**
   * From field in Content Stack
   */
  companyName: string

  /**
   * From field in Content Stack
   */
  displayName: string
}

export type IEventPayload = {
  attributes?:
    | IPageAttributes
    | PageEventAttributes
    | PageSlideshowAttributes
    | PageWebinarAttributes
    | PageVideoAttributes
    | PagePublicationIssueAttributes
    | PageRecipeAttributes
  category?: Category
  /**
   * Current page information, required for all pages
   */
  pageInfo: PageInfoAttributes | PublicationIssuePageInfo
}

export type PageInfoAttributes = {
  /**
   * Unique identifier for content items. Used by editorial teams to get a unique count of
   * the number of content items they have created during a specific time period.
   */
  pageID?: string

  /**
   * To be set on all pages. Should be visible headline on content pages (typically H1).For page types that don't use a headline, alternate logic will be needed to populate.
   */
  pageName: string

  /**
   * Set on all pages. Return the URL of the page being loaded. Value should be returned URL and not document.url (tracking code parameters should not be included)
   */
  destinationURL: string

  /**
   * The *visible* publish date shown on editorial content items. Used by editorial teams to track performance of stories they create during certain time frames. Populate in format Mmm DD, YYYY (e.g. Sep 06, 2020)
   */
  issueDate?: string

  /**
   * When a site search is made and results returned, set the number of search results returned
   */
  onsiteSearchResults?: string

  /**
   * When a site search is made and results returned, set the search string value
   */
  onsiteSearchTerm?: string

  /**
   * Used by editorial teams to track performance of stories they create. No value should be passed on non-content pages (there should not be a contributor value on home page or category pages etc.)
   */
  contributor?: Contributor[]

  /**
   *  `/${networkId}/${parentAdUnit}/${targeting?.pattern}`
   */
  adUnitPathCode?: string
}

export interface PageLegacyAttributes {
  /**
   * The value from 'old url' in Content Stack meant to capture url in previous environment
   */
  destinationPath: string

  /**
   * The publish date from the 'authored on' from previous CMS in Content Stack. Populate in format Mmm DD, YYYY (e.g. Sep 06, 2020)
   */
  issueDate: string

  /**
   * The value from the author field in previous CMS
   */
  contributor: string
}

export interface PageSeoAttributes {
  /**
   * Set on pages where the canonical URL has been explicitly defined in Content Stack.
   */
  canonicalURL: string

  /**
   * Return true/false based on field in Content Stack
   */
  hideFromSearchEngines: boolean
}

export interface PageSponsoredAttributes {
  /**
   * From field in Content Stack
   */
  externalFormID: string

  /**
   * From field in Content Stack
   */
  gateWithExternalForm: string

  /**
   * Return true/false based on field in Content Stack
   */
  gateWithSiteReg: boolean

  /**
   * Return true/false based on field in Content Stack
   */
  isSponsored: boolean

  /**
   * From field in Content Stack
   */
  campaignURL: string

  /**
   * From field in Content Stack
   */
  name: string
}

export interface IPageAttributes {
  /**
   * Identifier for current release of scm. Can be helpful in bug identification
   */
  scmVersion?: ScmVersion

  /**
   * Subbrand information, optional
   */
  subBrand?: string

  /**
   * SEO information, optional
   */
  seo?: PageSeoAttributes

  /**
   * Sponsored content information, optional
   */
  sponsored?: PageSponsoredAttributes

  /**
   * Legacy content information, optional
   */
  legacy?: PageLegacyAttributes
}

export interface PageEventAttributes extends IPageAttributes {
  events: {
    eventName: string
    eventStartDate: string
    eventEndDate: string
    eventURL: string
    eventLocation: string
    eventType: string
  }[]
}

export interface PageVideoAttributes extends IPageAttributes {
  video: {
    videoID: string
    videoService: string
    videoURL: string
    videoCredit: string
    videoDuration: number
  }
}

export interface PageSlideshowAttributes extends IPageAttributes {
  slideshow: {
    // TODO
  }
}

export interface PageRecipeAttributes extends IPageAttributes {
  mainIngredients: string[]
  cuisineType: string[]
  dayPart: string[]
  menuPart: string[]
}

export interface PageWebinarAttributes extends IPageAttributes {
  webinars: {
    webinarService: string
    webinarID: string
    on24WebinarID: string
    webinarURL: string
    webinarDuration: string
    webinarStartDate: string
    webinarTimeZone: string
    webinarMaterialTitle: string
    webinarMaterialURL: string
  } | null
}

export type PagePublicationIssueAttributes = IPageAttributes

export interface PublicationIssuePageInfo extends PageInfoAttributes {
  publicationIssueSource: string
  publicationTitle: string
}

export interface CategoryTerm {
  child: string
  grandchild: string
  parent: string
}

export interface Category {
  buyerJourney?: string
  contentFormat?: string
  pageType?: string
  keyword?: {
    name: string
  }[]
  series?: {
    name: string
  }[]

  programName?: string
  enhancedList?: string
  primaryTerm?: CategoryTerm
  secondaryTerm?: CategoryTerm[]
}

export interface ISlideshowEvent {
  destinationURL: string
  slideNumber: number
}

export interface INewsletterEvent {
  status: RegistrationStatus
  user_id?: string
  name?: string
  type?: string
  form_field?: string
  capture_tool?: string
}

export interface IRegistrationEvent {
  name: string
  status?: RegistrationStatus
  user_id?: string
  email_hashed?: string
  email?: string
  capture_tool?: string
}

export interface IGatingEvent {
  action: string
  name: string
  url: string
}

export interface INavigationEvent {
  name: string
  location: string
  section: string
  referring_page: string
  destination_page: string
}

export interface IUser {
  isDataCenter: boolean
}

export interface IDataLayerEvent {
  'gtm.start'?: any
  event?: EventType
  page?: IEventPayload
  slide?: ISlideshowEvent
  data?:
    | INewsletterEvent
    | IGatingEvent
    | IRegistrationEvent
    | ISocialShareData
    | INavigationEvent
  _clear?: boolean
  user?: IUser | IIirisUser | DataLayerUser | null
  ecommerce?: IEcommerce | null
}

export interface ISocialShareData {
  name: string
  location?: string
}

export type ModuleLocationModularPages =
  | 'homepage'
  | 'article'
  | 'modular_program'
  | 'media_center'
  | 'sidebar'
  | 'premier_page'
  | 'enhanced_list_landing'

export type ModuleLocationSidebar = 'sidebar'

export type ModuleLocation = ModuleLocationModularPages | ModuleLocationSidebar
