export const normaliseServiceName = (service: string) =>
  service.replace('@informa/', '').replace('@scm', '')

export const getWebUrl = (service: string, version: string) => {
  switch (normaliseServiceName(service)) {
    case 'licenseglobal-web':
      return `https://${version}-preview.licenseglobal.com`

    case 'quantum-web':
      return `https://${version}-preview.quantumbusinessnews.com`
  }

  return null
}

const getChromaticAppId = (service: string) => {
  switch (normaliseServiceName(service)) {
    case 'licenseglobal-web':
      return '61ae1647a80516003a3e6364'

    case 'quantum-web':
      return '621f4cb987d330003a405aa3'

    default:
      return null
  }
}

export const getStorybookRootUrl = (service: string, branchOrSha: string) => {
  const appId = getChromaticAppId(service)
  if (!appId) return null
  return `https://${branchOrSha.replace(/\//g, '-')}--${appId}.chromatic.com`
}

export const getStorybookBuildPreview = (service: string, buildId: string) => {
  const appId = getChromaticAppId(service)
  if (!appId) return null
  return `https://www.chromatic.com/build?appId=${appId}&number=${buildId}`
}

export const getPlatformServiceBaseUrl = (
  environment:
    | 'mock'
    | 'local'
    | 'test'
    | 'preview'
    | 'staging'
    | 'uat'
    | 'prod',
) => {
  switch (environment) {
    case 'prod':
      return 'https://api.platform.informa.com'
    case 'mock':
    case 'preview':
      return 'https://api.dev.platform.informa.com'
    case 'staging':
    default:
      return 'https://api.test.platform.informa.com'
  }
}
