import type { Action, ThunkAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authSlice } from './slices/auth.slice'
import { flagsSlice } from './slices/flags.slice'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import {
  AuthState,
  ConfigState,
  FlagsState,
  LayoutState,
  RootState,
} from './types'
import {
  createConfigState,
  generateState,
  GenerateStatePayload,
  storeAuthData,
} from './utils/generateState'
import { useRef } from 'react'
import { configSlice } from './slices/config.slice'
import { layoutSlice } from './slices/layout.slice'
import { getGatedCookiesKey } from './utils/externalGating'

const combinedReducers = combineReducers({
  auth: authSlice.reducer,
  flags: flagsSlice.reducer,
  config: configSlice.reducer,
  layout: layoutSlice.reducer,
})

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: combinedReducers,
    preloadedState,
  })
}

export const store = setupStore()

export const actions = {
  auth: authSlice.actions,
  flags: flagsSlice.actions,
  config: configSlice.actions,
  layout: layoutSlice.actions,
}

export const storeSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppStore = typeof store

export type AppDispatch = AppStore['dispatch']

export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()

export const selectors = {
  useRoot: () => useSelector<RootState, RootState>(state => state),
  useAuth: () => useSelector<RootState, AuthState>(state => state.auth),
  useFlags: () => useSelector<RootState, FlagsState>(state => state.flags),
  useConfig: () => useSelector<RootState, ConfigState>(state => state.config),
  useLayout: () => useSelector<RootState, LayoutState>(state => state.layout),
  useTestAutomation: () =>
    useSelector<RootState, FlagsState['testAutomation']>(
      state => state.flags.testAutomation,
    ),
  useFeatureToggles: () =>
    useSelector<RootState, FlagsState['featureToggles']>(
      state => state.flags.featureToggles,
    ),
  useExternalGatingPassed: (contentId: string) =>
    useSelector<RootState, string>(
      state =>
        state.auth.gatingWithExternalFormPassed[getGatedCookiesKey(contentId)],
    ),
}

export const useStateSetup = (payload: GenerateStatePayload) => {
  const storeRef = useRef(false)
  if (!storeRef.current) {
    storeRef.current = true
    const generatedState = generateState(payload)
    store.dispatch(authSlice.actions.init(generatedState.auth))
    store.dispatch(flagsSlice.actions.init(generatedState.flags))
    store.dispatch(configSlice.actions.init(generatedState.config))
  }
}
export * from './types'
export * from './utils/user'
export { mockedStore } from './mockedStore'
export { createConfigState, storeAuthData }
export { recordPassedExternalGating } from './thunks/auth'
export { ANONYMOUS } from './utils/externalGating'
