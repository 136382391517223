import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LayoutState } from '../types'
export const layoutInitialState: LayoutState = {
  spinnerLoader: false,
  gtagReady: false,
}
export const layoutSlice = createSlice({
  name: 'layout',
  initialState: layoutInitialState,
  reducers: {
    setSpinner(state, { payload }) {
      state.spinnerLoader = payload
    },
    update(state, { payload }: PayloadAction<Partial<LayoutState>>) {
      Object.assign(state, payload)
    },
    clear(state) {
      Object.assign(state, layoutInitialState)
    },
  },
})
